import React, { forwardRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import screen3 from "../images/screen3.png";
import screen5 from "../images/screen5.png";
import food1 from "../images/food1.png";
import food2 from "../images/food2.png";
import food3 from "../images/food3.png";
import food4 from "../images/food4.png";

const Booking = forwardRef((props: any, ref: any) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="bg-gray-100" ref={(bookingRef) => (ref.current[1] = bookingRef)}>
      {/* PC용 */}
      <div className="max-xl:hidden min-xl:block max-md:h-fit max-md:py-10 min-md:min-h-[calc(100vh-81px)]">
        <div className="grid max-md:grid-cols-1 grid-cols-6 w-full max-md:gap-0 gap-4">
          <div
            data-aos-offset="300"
            data-aos-easing="ease-in"
            data-aos="fade-right"
            className="max-md:col-start-1 max-md:p-4 col-start-2 col-span-2 flex items-center justify-center"
          >
            <div>
              <img src={screen3} className="rounded-md" style={{ width: 480 }} />
            </div>
          </div>
          <div className="max-md:col-start-1 max-md:p-4 max-md:h-fit h-[calc(100vh-81px)] col-start-4 col-span-2 flex">
            <div className="flex flex-col justify-around max-md:my-4 max-lg:my-40 mt-10 ml-2">
              <div>
                <p className="text-3xl">
                  <span className="text-theme-red font-bold">경기장 먹거리</span>
                  <span className="">를 한 눈에</span> <br />
                  <span className="">확인해 보세요!</span>{" "}
                </p>

                <div>
                  <p className="mt-8">
                    프로야구 10개 팀, 9개 구장의 모든 먹거리를 손쉽게 확인하세요
                  </p>

                  <p className="mt-1">
                    <span className="font-bold text-theme-red">상세 메뉴</span>
                    <span>와 </span>
                    <span className="font-bold text-theme-red">가격 정보</span>
                    <span>도 함께 제공돼요</span>
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-4 gap-2 flex items-center max-md:mb-0 max-md:mt-10 mb-20">
                <img src={food1} style={{ width: 100, height: "auto" }} />
                <img src={food2} style={{ width: 100, height: "auto" }} />
                <img src={food3} style={{ width: 100, height: "auto" }} />
                <img src={food4} style={{ width: 100, height: "auto" }} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile용 */}
      <div className="max-xl:block hidden max-md:h-fit max-md:py-10 min-md:min-h-[calc(100vh-81px)]">
        <div className="grid max-md:grid-cols-1 grid-cols-6 w-full max-md:gap-0 gap-4">
          <div className="max-md:col-start-1 max-md:p-4 max-md:h-fit h-[calc(100vh-81px)] col-start-2 col-span-2 flex">
            <div className="flex flex-col justify-around max-md:my-4 max-lg:my-40 mt-10 ml-2">
              <div>
                <p className="text-3xl">
                  <span className="text-theme-red font-bold">경기장 먹거리</span>
                  <span className="">를 한 눈에</span> <br />
                  <span className="">확인해 보세요!</span>{" "}
                </p>

                <div>
                  <p className="mt-8">
                    프로야구 10개 팀, 9개 구장의 모든 먹거리를 손쉽게 확인하세요
                  </p>

                  <p className="mt-1">
                    <span className="font-bold text-theme-red">상세 메뉴</span>
                    <span>와 </span>
                    <span className="font-bold text-theme-red">가격 정보</span>
                    <span>도 함께 제공돼요</span>
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-4 gap-2 flex items-center max-md:mb-0 max-md:mt-10 mb-20">
                <img src={food1} style={{ width: 100, height: "auto" }} />
                <img src={food2} style={{ width: 100, height: "auto" }} />
                <img src={food3} style={{ width: 100, height: "auto" }} />
                <img src={food4} style={{ width: 100, height: "auto" }} />
              </div>
            </div>
          </div>

          <div
            data-aos-offset="300"
            data-aos-easing="ease-in"
            data-aos="fade-right"
            className="max-md:col-start-1 max-md:p-4 col-start-4 col-span-2 flex items-center justify-center"
          >
            <div>
              <img src={screen3} className="rounded-md" style={{ width: 480 }} />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="max-md:pt-[120px] min-h-[calc(100vh-81px)]">
        <div className="grid max-md:grid-cols-1 grid-cols-6 w-full max-md:gap-0 gap-4">
          <div
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos="fade-right"
            className="max-md:col-start-1 max-md:p-4 col-start-2 col-span-2 flex items-center justify-center"
          >
            <div>
              <img src={screen3} className="rounded-md" style={{ width: 540 }} />
            </div>
          </div>
          <div className="max-md:col-start-1 max-md:p-4 min-h-[calc(100vh-81px)] col-start-4 col-span-2 flex items-center">
            <div className="flex flex-col justify-around h-full max-md:ml-0 ml-10 h-[80%]">
              <div>
                <p className="text-3xl">
                  <span className="text-theme-red font-bold">경기장 먹거리</span>
                  <span className="">를 한 눈에</span> <br />
                  <span className="">확인해 보세요!</span>{" "}
                </p>

                <div>
                  <p className="mt-8">
                    프로야구 10개 팀, 9개 구장의 모든 먹거리를 손쉽게 확인하세요
                  </p>

                  <p className="mt-1">
                    <span className="font-bold text-theme-red">상세 메뉴</span>
                    <span>와 </span>
                    <span className="font-bold text-theme-red">가격 정보</span>
                    <span>도 함께 제공돼요</span>
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-4 gap-2 flex items-center">
                <img src={food1} style={{ width: 100, height: "auto" }} />
                <img src={food2} style={{ width: 100, height: "auto" }} />
                <img src={food3} style={{ width: 100, height: "auto" }} />
                <img src={food4} style={{ width: 100, height: "auto" }} />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
});

export default Booking;
