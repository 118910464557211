import React, { forwardRef } from "react";
import screen4 from "../images/screen4.png";
import screen5 from "../images/screen5.png";

const Brand = forwardRef((props: any, ref: any) => {
  return (
    <div className="bg-gray-100" ref={(bookingRef) => (ref.current[1] = bookingRef)}>
      <div className="max-md:h-fit min-h-[calc(100vh-81px)]">
        <div className="grid max-md:grid-cols-1 grid-cols-6 w-full max-md:gap-0 max-md:py-12  gap-4">
          <div className="max-md:col-start-1 max-md:p-4 max-md:h-fit h-[calc(100vh-81px)] col-start-2 col-span-2 flex">
            <div className="flex flex-col justify-around max-md:ml-0 ml-2">
              <div>
                <p className="text-3xl">
                  <span className="text-theme-red font-black">경기 일정</span>
                  <span>을 간편하게 확인해 보세요!</span>
                </p>

                <div>
                  <p className="mt-8">
                    <span className="font-black text-yellow-500 text-lg black-han-sans-regular">
                      WHEN?{" "}
                    </span>
                    <span className="font-black text-theme-red text-xl black-han-sans-regular">
                      언제
                    </span>
                    <br />
                    <span className="font-black text-yellow-500 text-lg black-han-sans-regular">
                      WHERE?{" "}
                    </span>
                    <span className="font-black text-theme-red text-xl black-han-sans-regular">
                      어디서
                    </span>
                    <br />
                    <span className="font-black text-yellow-500 text-lg black-han-sans-regular">
                      WHO?{" "}
                    </span>
                    <span className="font-black text-theme-red text-xl black-han-sans-regular">
                      어느팀
                    </span>
                    <br />
                    <span>이 경기하는지 </span>
                    <span className="font-black text-theme-red">캘린더</span>
                    <span className="mt-8">로 쉽게 확인하고, 직관 계획을 세워보세요</span>
                  </p>

                  <p className="mt-8">
                    <span className="font-black text-theme-red">전체 경기 일정</span>도 캘린더에서
                    한눈에 파악 가능해요
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos="fade-in"
            className="max-md:col-start-1 max-md:p-4 col-start-4 col-span-2 flex items-center justify-center"
          >
            <div>
              <img src={screen5} className="rounded-md" style={{ width: 480 }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Brand;
