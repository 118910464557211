import React, { forwardRef } from "react";
import screen4 from "../images/screen4.png";

const Brand = forwardRef((props: any, ref: any) => {
  return (
    <div className="bg-yellow-400" ref={(bookingRef) => (ref.current[1] = bookingRef)}>
      <div className="max-md:h-fit">
        <div className="grid max-md:h-fit h-[calc(100vh-81px)] max-md:grid-cols-1 grid-cols-6 w-full max-md:gap-0 max-md:pt-10  gap-4">
          <div className="max-md:col-start-1 max-md:p-4 col-start-2 col-span-2 flex">
            <div className="flex flex-col justify-around max-md:ml-0 ml-2">
              <div>
                <p className="text-3xl">
                  <span className="text-theme-red font-black">선호구장</span>
                  <span>을 등록해</span> <br />
                  {/* <span className="text-black font-bold">선호 구장</span> */}
                  <span>자주 가는 구장을 관리해 보세요!</span>{" "}
                </p>

                <div>
                  <p className="max-md:my-8 mt-8">
                    <span className="font-black text-black">선호 구장</span>
                    <span>을 등록하여 자주 가는 구장에 손쉽게 접근하고,</span>
                    <br />
                    <span>원하는 정보를 빠르게 확인하세요.</span>
                  </p>

                  <p className="black-han-sans-regular text-theme-red mt-8 max-md:my-4">
                    #즐겨찾기 #고척스카이돔 #구장먹거리
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            data-aos-offset="300"
            data-aos-easing="ease-in"
            data-aos="fade-in"
            className="max-md:col-start-1 max-md:px-4 col-start-4 col-span-2 flex items-end justify-center"
          >
            <div>
              <img src={screen4} className="rounded-md max-md:w-[340px] w-[500px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Brand;
