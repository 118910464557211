import React, { forwardRef, useEffect } from "react";
import img1 from "../images/img1.jpeg";
import screen1 from "../images/screen1.png";
import apple from "../images/apple-logo.png";
import pizza from "../images/pizza.png";
import chicken from "../images/chicken.png";
import baseball2 from "../images/baseball-img2.png";
import baseball1 from "../images/baseball-img.png";
import mainLogo from "../images/gujang-logo.png";
import kakao from "../images/kakao.png";

const Home = forwardRef((props: any, ref: any) => {
  const { Kakao }: any = window;
  const height = window.innerHeight;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: mainLogo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const realUrl = "https://gujang-food.vercel.app/";
  const resultUrl = window.location.href;

  // 재랜더링시에 실행되게 해준다.
  useEffect(() => {
    Kakao.cleanup();
    Kakao.init("d2aa8009ebec66522514a32292869dde");
  }, []);

  const shareKakao = () => {
    Kakao.Share.sendDefault({
      objectType: "feed",
      content: {
        title: "프로야구 직관 필수 앱 구장먹거리 ⚾️",
        description: `구장먹거리 앱 구경하고 설치하러 가기!`,
        imageUrl: "https://i.postimg.cc/W115mDjs/image.jpg",
        link: {
          mobileWebUrl:
            "https://apps.apple.com/kr/app/%EA%B5%AC%EC%9E%A5%EB%A8%B9%EA%B1%B0%EB%A6%AC/id6553999145",
        },
      },
      buttons: [
        {
          title: "구경하러 가기",
          link: {
            mobileWebUrl:
              "https://apps.apple.com/kr/app/%EA%B5%AC%EC%9E%A5%EB%A8%B9%EA%B1%B0%EB%A6%AC/id6553999145",
          },
        },
      ],
    });
  };

  return (
    <div className="bg-gray-100" ref={(homeref) => (ref.current[0] = homeref)}>
      <div className="mx-auto">
        <div
          className={
            "max-2xl:px-[10px] min-h-[calc(100vh)] flex items-center justify-center flex-col bg-image"
          }
        >
          <div className="grid max-md:pt-[200px] max-xl:px-[10px] max-md:grid-cols-1 mx-auto grid-cols-6 mb-[60px]">
            <div className="max-md:col-span-1 col-span-2 flex flex-col justify-center">
              <p className="font-black text-4xl  black-han-sans-regular text-yellow-200">
                프로야구 직관 필수 앱
              </p>
              <div className="flex items-center">
                <img src={mainLogo} className="w-[42px] mr-1" />
                <p className="font-black text-[44px] black-han-sans-regular text-white mt-[20px]">
                  구장먹거리
                </p>
              </div>
              <p className="text-xl black-han-sans-regular text-white mt-2">
                이제는 각 구장마다 먹거리가 무엇이 있는지 검색하지 말고
                <br /> 앱 하나로 해결하세요!
              </p>

              <div
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/kr/app/%EA%B5%AC%EC%9E%A5%EB%A8%B9%EA%B1%B0%EB%A6%AC/id6553999145",
                    "_blank",
                    "noopener, noreferrer"
                  )
                }
                className="download-app mt-10 cursor-pointer active:scale-95"
              >
                <img
                  className="apple-logo mr-2"
                  src={apple}
                  style={{ width: 30, height: "auto" }}
                />

                <div>
                  <p className="text-xs">Download on the</p>
                  <p className="font-bold text-lg">App Store</p>
                </div>
              </div>

              <div className="share-kakao" onClick={shareKakao}>
                <img src={kakao} className="w-[20px]" />
                <p>카카오톡 공유하기</p>
                <div className="w-[20px]"></div>
              </div>
            </div>
            <div className="max-md:col-span-1 max-md:mt-10 col-start-4 col-span-3">
              <div className="flex" style={{ alignItems: "flex-end" }}>
                <div className="pb-[100px] max-lg:hidden">
                  <img src={pizza} style={{ width: 40, height: "auto" }} className="mr-6" />
                </div>

                <img src={screen1} className="max-2xl:w-[400px] w-[500px]" />

                <div className="ml-2 max-lg:hidden pb-[40px]">
                  <div className="ml-4">
                    <img src={chicken} style={{ width: 40, height: "auto" }} />
                  </div>
                  <div className="flex mt-40" style={{ alignItems: "flex-end" }}>
                    <img src={baseball1} className="ml-2" style={{ width: 20, height: "auto" }} />
                    <img src={baseball2} style={{ width: 50, height: "auto" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="max-sm:mt-0 max-w-[1440px] mx-auto"></div>
      </div>
    </div>
  );
});

export default Home;
