import React from "react";
import baseball from "../images/baseball-img.png";

const Footer = () => {
  return (
    <div className="bg-gray-200">
      <div className="max-2xl:px-[20px] grid py-8 lg:grid-cols-4 max-sm:grid-cols-1 mx-auto max-w-[1440px] max-sm:h-fit">
        <div className="col-span-4">
          <p className="text-gray-500 text-xs text-end">IMAGE ICONS: Flaticon.com</p>
          <p className="text-gray-500 text-xs text-end">
            @All right reserved <span className="font-bold">구장먹거리</span>.
          </p>
          {/* <p className="text-gray-500 text-xs text-end font-bold">구장먹거리</p> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
